//******************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//******************************************************************************
.site-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
}
.main-nav {
    @include media-breakpoint-between(xs, md) {
        background: #fff;
        .navbar {
            padding: 0;
            .navbar--primary {
                padding-top: 3rem;
                .nav-item{
                    &:not(:last-child){
                        margin-bottom: 2.5rem;
                    }
                    

                }

            }
            .menu-header-menu-container{
                padding: 1.5rem;
                width: 100%;
            }
            .navbar-brand{
                padding: 4rem 0 2.5rem 1.5rem;
            }
        }
        .container{
            max-width: 100vw;
            padding-left: 0;
            padding-right: 0;
            .row{
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .navbar {
        width: 100%;
        min-height: 3.2rem;
        z-index: 90;
        padding: 0;

        .navbar--primary {
            height: 100%;

            // MAIN NAVIGATION
            .menu-item {
                position: relative;

                // MAIN LINK
                .nav-link {
                    padding: 1rem;
                    font-weight: 600;
                    color: inherit;
                    font-weight: 400;
                    font-size: 1.6rem;
                    margin-right: 7rem;
                    margin-top: 1rem;
                    
                    @include fade-transition(0.3s);
                    &:last-child{
                        margin-right: none;
                    }
                    &:hover {
                        &:before {
                            background: #000;
                        }
                        &:after {
                            color: inherit;
                        }
                        text-decoration: none;
                        color: $primaryColor;
                    }
                    &:after {
                        display: none;
                    }
                    @media screen and (max-width: 1540px){
                        margin-right: 4rem;
                    }
                }

                // SUB NAVIGATION TOGGLE
                .menu-dropdown-toggle {
                    display: none;
                }

                // SUB NAVIGATION
                .dropdown-menu {
                    padding: 0;
                    border: 0;
                    border-radius: 0;
                    margin-top: 1.5rem;
                    background: #fff;
                    // SUB LINK
                    .menu-item{
                        height: unset;
                        margin-bottom: 0;
                        @media screen and (max-width: 1365px){
                            &:not(:last-child){
                                margin-bottom: 1.5rem;
                            }
                        }
                    }
                    .dropdown-item {
                        position: relative;
                        display: block;
                        width: 100%;
                        padding: 0.5rem 3rem 0.5rem 1rem;
                        font-weight: 400;
                        font-size: 1.6rem;
                        color: $bodyColor;
                        white-space: nowrap;
                        border: 0;
                        height: fit-content;
                        @include fade-transition(0.3s);
                        &:hover {
                            color: #000;
                            background: transparent;
                            &:after {
                                color: #000;
                            }
                        }
                        &:after {
                            display: block;
                            color: #000;
                            content: none;
                            font: normal normal normal 1.8rem/1 FontAwesome;
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            font-weight: 700;
                        }
                        @include media-breakpoint-up(lg) {
                            color: white;
                        }
                    }
                    .menu-item{
                        margin-left: 1rem;
                        @include media-breakpoint-up(lg) {
                            background-color: $primaryColor;
                            margin-left: 0;
                            padding: 1.5rem 2.5rem;
                            &:not(:last-child){
                                border-bottom: white solid 1px;
                            }
                            
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        margin-top: 0;
                    }

                }

                // DESKTOP
                @include media-breakpoint-up(lg) {
                    height: 110px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                        background: #fff;
                        .nav-link {
                            color: $primaryColor;
                        }
                        .dropdown-menu {
                            display: block;
                            opacity: 1; 
                            position: absolute; 
                            top: 110px; 
                            left: 0;
                        }
                    }
                }

                // TABLET
                @include media-breakpoint-only(lg) {
                    // MAIN LINK
                    .nav-link {
                        padding: 0.6rem;
                    }
                    // SUB NAVIGATION
                    .dropdown-menu {
                        // SUB LINK
                        .dropdown-item {
                            padding: 0.5rem 0.6rem;
                        }
                    }
                }

                // MOBILE
                @include media-breakpoint-between(xs, md) {
                    &:hover {
                        .nav-link {
                            color: $primaryColor;
                        }
                        .menu-dropdown-toggle {
                            .menu-dropdown-toggle-icon {
                                &:before,
                                &:after {
                                    background: $primaryColor;
                                }
                            }
                        }
                    }

                    // MAIN LINK
                    .nav-link {
                        padding: 0.2rem 0;
                        @media screen and (max-width: 1365px){
                            margin-bottom: 2.5rem;
                          
                        }
                    }

                    // SUB NAVIGATION
                    .dropdown-menu {
                        &.open {
                            display: block;
                            padding: 0;
                            background: transparent;
                        }
                        // SUB LINK
                        .dropdown-item {
                            padding: 0.5rem 0;
                            &:after {
                                right: 0;
                            }
                            @media screen and (max-width: 1365px) {
                                padding: 0
                            }
                        }
                    }

                    // SUB NAVIGATION TOGGLE
                    .menu-dropdown-toggle {
                        padding: 0;
                        border: 0;
                        border-radius: 0;
                        position: absolute;
                        top: .75rem;
                        right: 0;
                        display: block;
                        background: transparent;
                        .menu-dropdown-toggle-icon {
                            position: relative;
                            cursor: pointer;
                            height: 1rem;
                            width: 1rem;
                            display: block;
                            &:before,
                            &:after {
                                background: $bodyColor;
                                content: '';
                                display: block;
                                width: 1rem;
                                height: 0.20rem;
                                top: 0.35rem;
                                position: absolute;
                                @include fade-transition(0.3s);
                            }
                            &:before {
                                -webkit-transform: rotate(-180deg);
                                transform: rotate(-180deg);
                            }
                            &:after {
                                -webkit-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                        
                        &.open {
                            .menu-dropdown-toggle-icon {
                                &:after {
                                    display: none;
                                }
                                &:before{
                                    background-color: $primaryColor;
                                }
                            }
                        }
                    }
                }
                &:hover{
                    .nav-item{
                        color: $primaryColor;
                    }
                }
            }
        }
        @include media-breakpoint-between(xs, md) {
            .navbar-collapse{
                background-color: #f4f4f4;
                color: $bodyColor;
                display: block;
                position: absolute;
                top: 85px;
                width: 100%;
                opacity: 0;
                transition: all .3s ease-in-out;
                height: calc(100vh - 85px);
                pointer-events: none;

                &.collapse{

                    opacity: 0;
                    &:not(.show) {
                        opacity: 0;
                    }
                }
                &.collapsing[style*="height"] {
                    opacity: 1;
                    height: calc(100vh - 85px);
                    
                }
                &.show{
                    height: calc(100vh - 85px);
                    pointer-events: auto;
                }
                &.collapsing{

                    opacity: 0;
                }
                &.collapse.show {
                    
                    opacity: 1;

                }
                iframe{
                    position: absolute;
                    bottom: 5rem;
                    left: 1.5rem;
                }
                
            }
            
        }
        @include media-breakpoint-up(lg){
            .navbar-collapse{
                margin-left: 7rem;
            }
        }

        @include media-breakpoint-up(xl){
            .navbar-collapse{
                margin-left: 23rem;
            }
        }
    }
}

// MAIN NAVIGATION TOGGLE
.navbar-toggler {
    display: none;
    @include media-breakpoint-between(xs, md) {
        padding: 0;
        border: 0;
        border-radius: 0;
        position: absolute;
        top: 3.5rem;
        right: 1.5rem;
        height: 3rem;
        display: block;

        .navbar-toggler-icon {
            position: relative;
            display: inline-block;
            width: 3.5rem;
            height: 0.25rem;
            background: 0 0;
            transition: all .2s ease-in-out;
            &:before,
            &:after {
                background: #000;
                content: '';
                display: block;
                width: 3.5rem;
                height: 0.1rem;
                position: absolute;
                transition: all .2s ease-in-out;
            }
            &:before {
                top: -0.7rem;
            }
            &:after {
                top: 0.7rem; 
            }
        }
        &[aria-expanded="true"]{
            .navbar-toggler-icon {
                background: transparent;
                &:before,
                &:after {
                    top: 0;
                    height: 0.10rem;
                }
                &:before {    
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                &:after {
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }  
        }

        &:hover {
            background: transparent;
        }
    }
}