// RESPONSIVE FONTS @include fluid-type(1.3rem, 1.7rem);
@mixin fluid-type($min-font-size: 1.3rem, $max-font-size: 2.2rem, $lower-range: 540px, $upper-range: 960px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}
@mixin fluid-height($min-height: 350px, $max-height: 500px, $lower-range: 540px, $upper-range: 960px){
  height: calc(#{$min-height} + #{(($max-height / ($max-height * 0 + 1)) - ($min-height / ($min-height * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    height: $min-height;
  }
  @media screen and (min-width: $upper-range){
    height: $max-height;
  }
}

// FADE TRANSITION 
@mixin fade-transition($duration) {
	transition-property: all;
    transition-duration: $duration;
    transition-timing-function: ease-out;
}

// BACKGROUND IMAGES 
@mixin object-fit($background-size) {
	-o-object-fit: $background-size;
	object-fit: $background-size;
}