//******************************************************************************
// SECTIONS
// Styles for the various sections used throughout the site
//******************************************************************************

$pbPadding: 6rem;
$pbPadding-tablet: 6rem;

.container {
	//background-color: red !important;
}

.inner-spacing > * + * {
	margin-top: 2rem;
	margin-bottom: 0;
}

@include media-breakpoint-down(md) {
	.mobile-spacing {
		margin-top: 2rem;
	}
}

//CARDS 
.pb-block-header + .cards {
	margin-top: 5rem;
}
.cards {
	> .card {
		margin-bottom: 2rem;
	}

	&__cta {
		margin-top: 5rem;
	}
}
.card {
	&__outer {
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&__inner {
		position: relative;
		height: 100%;
		padding: 4rem 2rem;
	}

	&__heading {
		display: block;
	}

	&__image {
		text-align: center;
	}
}

//SITEWIDE - PAGEBUILDER
.pb {
	padding-top: $pbPadding;
	padding-bottom: $pbPadding;

	border-bottom: 1px solid black; // TO REMOVE
	&.bg-white {
		//padding-bottom: 0; // TO ADD BACK IN
	}

	@include media-breakpoint-down(md) {
		padding-top: $pbPadding-tablet;
		padding-bottom: $pbPadding-tablet;
	}

	&:not(.bg-white) {
		//margin-top: $pbPadding; //TO ADD BACK IN

		@include media-breakpoint-down(md) {
			//margin-top: $pbPadding-tablet; //TO ADD BACK IN
		}
	}

	&:last-of-type {
		&.bg-white {
			margin-bottom: $pbPadding;

			@include media-breakpoint-down(md) {
				margin-bottom: $pbPadding-tablet;
			}

			@include media-breakpoint-down(sm) {
				margin-bottom: 5rem;
			}
		}
	}

	&.pb-banner-cta {
		padding-top: 0;
		padding-bottom: 0;

		.inner {
			padding-top: $pbPadding;
			padding-bottom: $pbPadding;
		}
	}
}

.pb,
.pb-hero {
	&.bg-primary {
		.btn,
		button {
			background-color: var(--accent);

			&:hover {
				background-color: var(--textclr);
				color: white;
			}
		}
	}

	&.bg-accent {
		.btn,
		button {
			&:hover {
				background-color: var(--textclr);
				color: white;
			}
		}
	}
}

//HERO
.pb-hero {
	overflow: hidden;
	.container {
		display: block !important;
	}

	.row {
		display: flex !important;
	}

	.btn {
		margin-top: 3rem;
	}

	.hero__inner {
		position: relative;
		z-index: 5;
		margin-top: auto;
		margin-bottom: auto;
	}

	&--extra-padding {
		.pb-hero__left {
			padding-bottom: 15rem;

			@include media-breakpoint-down(sm) {
				padding-bottom: 3rem;
			}
		}
	}

	&__left,
	&__right {
		position: relative;
		a {
			color: inherit;
		}
	}

	&__right {
		.objectfit {
			position: relative;
		}
	}

	&__left {	
		z-index: 1;
		display: flex;
		flex-direction: column;
		padding-top: 14rem;
		padding-bottom: 14rem;
		.hero__inner {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}

	.row {
		margin-left: 0;
		margin-right: 0;
	}

	&--slider {
		.objectfit {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.pre-header {
			font-weight: 600;
			margin-bottom: 4rem;
			font-size: 1.6rem;
		}

		.pb-hero__left {
		    padding-top: 4rem;
			padding-bottom: 20rem;
		}

		.hero__inner {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

//SITEWIDE - VIDEO
.video-container {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
    	background-color: #000;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .btn-video-play {
    	width: 100%;
	    height: 100%;
	    position: absolute;
	    font-size: 0;
	    border: 0;
    	background: transparent;
    	&:focus {		
			outline: none;
	  		box-shadow: none;
		}
	    &:before {
	    	position: absolute;
	    	content: '';
	    	z-index: 1;
	    	width: 0;
			height: 0;
			margin-left: 2px;
			border-style: solid;
			border-width: 9px 0 9px 12px;
			border-color: transparent transparent transparent var(--textclr);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	    &:after {
	    	position: absolute;
	    	content: '';
	    	width: 80px;
	    	height: 80px;
	    	border-radius: 80px;
	    	display: block;
	    	background: var(--primary);
	    	top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
	    }
	    &:hover {
	    	&:before {
	    		border-color: transparent transparent transparent white;
	    	}
	    	&:after {
	    		background: var(--textclr);
	    	}
	    }
    }

    @include media-breakpoint-between(xs,sm) {
    	.btn-video-play {
    		&:before {
				border-width: 7px 0 7px 10px;
			}
		    &:after {
		    	width: 60px;
		    	height: 60px;
		    	border-radius: 60px;
		    }
    	}
    }
}

//BLOG
.blog {
	padding-top: $pbPadding;
	padding-bottom: $pbPadding;
	border-bottom: 1px solid black; // TO REMOVE

	@include media-breakpoint-down(md) {
		padding-top: $pbPadding-tablet;
		padding-bottom: $pbPadding-tablet;
	}
}

//SINGLE
.article {
	padding-top: $pbPadding;
	padding-bottom: $pbPadding;
	border-bottom: 1px solid black; // TO REMOVE

	@include media-breakpoint-down(md) {
		padding-top: $pbPadding-tablet;
		padding-bottom: $pbPadding-tablet;
	}
}


// TO REMOVE
.pb-module-request {
	color: red;
	font-size: 1.8rem;
}

//PAGE HERO
.page-hero{
	margin-bottom: 7rem;
	position: relative;
	@include fluid-height(350px, 500px);
	&>.container{
		padding-right: 0;
		padding-left: 0;
		width: 100vw;
		max-width: 100vw;
		.row{
			margin-right: 0;
			margin-left: 0;
		}
	}
	&__content{
		padding-left: 0;
		padding-right: 0;
		position: relative;
		height: 100%;
	}
	&__text-wrapper{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

	}
	&__image{
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	&:before{
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0.2;
		background: linear-gradient(0.25turn, #2D4052, #FFFFFF);
		z-index: 1;
		mix-blend-mode: multiply;
	}
	@media screen and (max-width: 1365px){
		margin-bottom: 0;
	}
}
.links-section{
	padding: 7.5rem 0;
	@media screen and (max-width: 1365px){
		.link{
			&:not(:last-child){
			margin-bottom: 2.5rem;
			}
		}
		
	}
}
.bottom-tiles{
	&__tile{
		padding-left: 0;
		padding-right: 0;
		
		&:not(:last-child){
			margin-right: 3rem;
		}
		@media screen and (max-width: 1365px){
			flex-basis: 0;
			&:not(lastchild){
				margin-bottom: 2.5rem;
			}

		}
		@media screen and (min-width: 1366px){
			max-width: calc(33.333% - 2rem);
		}
	}
	&__text-wrapper{
		padding: 3rem 7rem 2.5rem 2.5rem;
	}
	&__inner-text-wrapper{
		p{
			margin-bottom: 5rem;
		}
	}

}
.row{
	@media screen and (max-width: 1365px){
		margin-left: 0;
		margin-right: 0;
	}
}
.container.bg-grey{
	@media screen and (max-width: 1365px){
		max-width: 100vw;
	}
}