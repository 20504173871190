//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
html {
    font-size: 62.5%;
}

body {
    color: var(--textclr, $font-clr);
    font-size: 1.5rem;
    font-family: $font-family;
    font-weight: 400;
}

p {
    line-height: 1.5;
}

strong,
b {
    font-weight: 600;
}

img {
    height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p {
    margin-top: 2rem;
}

a {
    text-decoration: underline;
    color: var(--accent, $primary);

    &:hover {
        text-decoration: none;
        color: var(--accent, $primary);
    }
}

.btn,
button {
    background-color: var(--primary);
    color: var(--textclr);
    padding: 9px 13px 7px;
    text-decoration: none;
    border: none;
    border-radius: var(--border-radius, 4px);
    display: inline-block;
    font-weight: 400;
    cursor: pointer;

    &:hover {
        background-color: var(--accent);
        color: white;
    }

    + .btn,
    + button {
        margin-left: 2rem;
    }
}


// ASPECT RATIO
// -----------------------------------------------------------------------------
img, input[type="image"], video, embed, iframe, marquee, object, table {
    aspect-ratio: attr(width) / attr(height);
}

// FLEX
// -----------------------------------------------------------------------------
.row-reverse {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
    }
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}

// FOCUS-VISIBLE
// -----------------------------------------------------------------------------
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
.js-focus-visible .focus-visible {
    outline: 1px dashed var(--primary);
}

html[data-useragent*="rv:11.0"] {
    
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}
.compat-object-fit {
    background-size: cover;
    background-position: center center;
    img {
        opacity: 0 !important;
    }
}

svg.icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    fill: currentColor;
    position: relative;
    top: 3px;
    margin: -2px 5px 0 0;
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: 2rem;
        z-index: 1000;
        background: #000000;
        padding: 1rem 1.8rem;
        border: 4px solid white;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}