//******************************************************************************
// TYPOGRAPHY
//******************************************************************************

h1,h2,h3,h4,h5,h6, .alpha, .beta, .gamma, .delta, .epsilon, .zeta {
	font-family: $heading;
	font-weight: 400;
	margin-bottom: 0;
}

h1, .alpha {
	@include fluid-type(2.4rem, 5rem);
	line-height: 1.15;
	font-family: $font-family;
	color: white;
}

h2, .beta {
	@include fluid-type(2.2rem, 3rem);
	line-height: 1.2;
}

h3, .gamma {
	@include fluid-type(2.2rem, 2.2rem);
	line-height: 1.2;
}

h4, .delta {
	@include fluid-type(2.1rem, 2.1rem);
	line-height: 1.2;
}

h5, .epsilon {
	@include fluid-type(1.8rem, 2.8rem);
	line-height: 1.2;
}

h6, .zeta {

}
.eyebrow{
	@include fluid-type(1.6rem, 3rem);
	font-family: $heading;
	line-height: 1;
	color: white;
	margin-bottom: 1.5rem;
	@media screen and (max-width: 1365px){
		margin-bottom: 2.5rem;
	}
}
.pre-header {
	display: block;
	font-weight: 700;

	.pb & {
		color: #7B7B7B;
	}
}
p{
	font-size: 1.6rem;
	font-family: $font-family;
}
.primary-span{
	font-weight: 700;
	color: $primaryColor;
}
blockquote {
	background-color: var(--primary);
	padding: 5rem;
	text-align: center;
	font-size: 2.7rem;
	color: white;
	font-family: 'Sorts Mill Goudy', serif;
	font-style: italic;

	p {
		margin: 0;
	}

	em {
		font-size: 1.5rem;
		color: var(--textclr);
		display: block;
		margin-top: 1rem;
	}
}

.link{
	color: $bodyColor;
	font-weight: 700;
	text-decoration: none;
	position: relative;
	margin-right: 7.5rem;
	width: fit-content;
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	&:after{
		content: "";
		background: url("../assets/images/arrow-icon.svg") center no-repeat;
		width: 25px;
		height: 25px;
		margin-left: 15px;
		display: inline-block;
	}
	&:last-child{
		margin-right: 0;
	}
}
a{
	color: $primaryColor;
	text-decoration: none;
	font-size: 1.6rem;
	&:hover{
		color: $primaryColor;
		text-decoration: underline;
	}
}