//******************************************************************************
// FORMS & ERRORS
//******************************************************************************

// Base Inputs
// -----------------------------------------------------------------------------
input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    width: 100%;
    min-width: 100px;
    height: 50px;
    font-weight: 700;
    font-size: 16px;
    border: 0;
    padding: 0 18px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1px solid #cbcbc7;
    background: #fff;
    top: 3px;
    padding: .1rem;
    display: inline-block;
    vertical-align: middle;
    appearance: none;

    &:checked {
        background: var(--primary);
    }
}


// Placeholder
// -----------------------------------------------------------------------------
::-webkit-input-placeholder {
    color: #ddd;
}
::-moz-placeholder {
    color: #ddd;
}
:-ms-input-placeholder {
    color: #ddd;
}
:-moz-placeholder {
    color: #ddd;
}

// Styles
// -----------------------------------------------------------------------------
.form {
    padding: 4rem;

    &--filter {
        ul {
            padding: 0;
        }
    }
}

.frm_fields_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    > .frm_form_field,
    > .frm_submit {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.frm_form_field {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 2rem;
}
.frm_half {

    @include media-breakpoint-down(sm) {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.frm_description {
    font-size: 1.3rem;
    opacity: .8;
}

.frm_none_container {
    .frm_primary_label {
        display: none;
    }
}

.frm_screen_reader,
.frm_verify {
    display: none;
}

.frm_error_style {
    margin-bottom: 3rem;
}

.frm_error,
.frm_error_style {
    color: red;
}