//******************************************************************************
// FOOTER
//******************************************************************************
.site-footer {
	background-color: $bodyColor;
	p{
		color: white;
	}
	.row.contact-row{
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
		text-align: center;
		background-color: white;
		p,a{
			font-size: 2rem;
			line-height: 2.4rem;
			}
		p{
			color: $bodyColor;
		}
		@media screen and (max-width: 1365px){
			padding-top: 5rem;
			padding-bottom: 5rem;
			p,a{
				font-size: 1.6rem;
			}
			
		}
	}
	.container{
		padding-top: 5rem;
		padding-bottom: 2.5rem;
	}
	.logo-container{
		margin-bottom: 5rem;
		@media screen and (max-width: 1365px){
			margin-bottom: 2.5rem;
			.navbar-brand{
				margin-bottom: 2.5rem;
			}
		}
	}
	.footer-links-container{
		text-align: right;
		@media screen and (max-width: 1365px){
			text-align: left;

		}
	}
	.link--footer{
		padding-left: 0.5rem;
		font-weight: 700;
		&:not(:last-child){
			padding-right: 1rem;
			border-right: solid 2px $primaryColor;
		}
		&:first-child{
			padding-left: 0;
		}
		@media screen and (max-width: 1365px){
			border-right: none!important;
			padding-left: 0;
		}
	}
	.link.link--orange{
		color: white;
		display: inline-flex;
		&:after{
			background: url('../assets/images/arrow-icon-orange.svg');
		}
		&:hover{
			color: $primaryColor;
			text-decoration: none;
		}
	}
	.copyright-links{
		margin-top: 7.5rem;
		p{
			display: inline;
		}
		&>div:first-child{
			&>*{
				padding-left: 0.5rem;
				&:not(:last-child){
					padding-right: 1rem;
					border-right: solid 2px white;
			}		
				&:first-child{
					padding-left: 0;

				}
			}
			@media screen and (max-width: 1365px){
				border-right: none!important;
			}
		}
		&>div:last-child{
			text-align: right;
			@media screen and (max-width: 1365px){
				text-align: left;
				margin-top: 1.5rem;
			}
		}
		.link--copyright{
			color: white;
			&:hover{
				color: $primaryColor;
				text-decoration: none;
			}
		}
		@media screen and (max-width: 1365px){
			margin-top: 2.5rem;
		}
	}
}