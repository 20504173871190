//******************************************************************************
// BRAND & SKINS
// colors, specific typography, logos
//******************************************************************************

$bg-white: #fff;
$primaryColor: #FF7800;
$accentColor: #2D4052;
$bodyColor: #252525;
$bg-grey: #F4F4F4;

$heading: 'Noto Serif', serif;
$font-family: 'Roboto', sans-serif;

.bg-white {
    background-color: $bg-white;
}
.bg-primary {
    background-color: var(--primary) !important;
}
.bg-primary-light {
    background-color: var(--primary-light) !important;
}
.bg-accent {
    background-color: var(--accent) !important;
}
.bg-light {
	background-color: #EDEDED !important;
}
.bg-dark {
	color: white;
}
.bg-grey{
    background-color: $bg-grey!important;
}