//******************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//******************************************************************************

// Content
// -----------------------------------------------------------------------------
.document {

}

.main {
    flex: 1 0 auto;
}

.content {
    position: relative;
}

// Spacing
// -----------------------------------------------------------------------------
.spacer {
    margin: 32px 0;
}

// Search bar
// -----------------------------------------------------------------------------
.search-form {
    width: 100%;
    height: 100px;
    font-size: 20px;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    transform: translateY(-100px);
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;

    ::-webkit-input-placeholder {
      color: #fff;
    }
    ::-moz-placeholder {
      color: #fff;
    }
    :-ms-input-placeholder {
      color: #fff;
    }
    :-moz-placeholder {
      color: #fff;
    }

    .search-field {
        width: 100%;
        height: 100%;
        background-color: #000;
        color: #fff;
        padding: 0 80px 0 30px;
        border: 0;
        outline: 0;
    }

    .search-submit {
        width: 80px;
        height: 80px;
        background: none;
        color: #fff;
        line-height: 80px;
        text-align: center;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
    }

    .search-visible & {
        transform: translateY(0);
    }
}

.m-b-20 {
    margin-bottom: 2rem;
}

// TO BE REMOVE - GRID TEMPLATE STYLE

.grid-template header {
  .bootstrap__inner {
    color: #ffffff;
    background-color: #67E1CC;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      &:before {  
        display: block;
      }
    }

  }

  .bootstrap__inner-browser {
    color: #ffffff;
    background-color: #222222;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .max-width {
    font-weight: 600;
  }

  @include media-breakpoint-only(xxl) {
    h1 {
      &:before {
        content: 'Extra Large Desktop if > 1820px';
      }
    }
  }
  @include media-breakpoint-only(xl) {
    h1 {
      &:before {
        content: 'Large Desktop if > 1540px';
      }
    }
  }
  @include media-breakpoint-only(lg) {
    h1 {
      &:before {
        content: 'Small Desktop if > 1350px';
      }
    }
  }
  @include media-breakpoint-only(md) {
    h1 {
      &:before {
        content: 'Landscape & Portrait Tablet = 95% wide';
      }
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      &:before {
        content: 'Mobile Landscape & Portrait if < 768px';
      }
    }
  }
}

.grid-template section {
  position: relative;

  &:nth-child(odd) {
    .bootstrap__inner {
      background-color: #ff00ff;
    }
  }
  &:nth-child(even) {
    .bootstrap__inner {
      background-color: rgba(#ff00ff, 0.5);
    }
  }

  .bootstrap__inner {
    color: #ffffff;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include media-breakpoint-between(xs,md) {
    &.bootstrap__custom {
      .bootstrap__column {
        flex-basis: unset;
          flex-grow: unset;
          max-width: 100%;
      }
    }
  }
}